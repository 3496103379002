<template lang="pug">
  .quick-start-page
    .hero 
      .nio-jumbo-2.text-white Shop Builder
      .nio-p-large.text-canvas Customize your data shop storefront.
    .steps 
      h1.nio-h1.text-primary-darker Build it and they will come
      p.nio-p.text-primary-dark Use your brand’s unique features to customize your data shop and stand out from the crowd.
      NioSlatGroup
        NioIconLinkSlat(
          icon-name="display-website" 
          display-only
        )
          template(v-slot:title) Register a domain name to point your data shop to it
          template(v-slot:subtitle) Visit the Narrative knowledge base for detailed instructions.
          template(v-slot:action)
        NioIconLinkSlat(iconName="display-warning" display-only)
          template(v-slot:title) Use brand assets to customize your shop
          template(v-slot:subtitle) Define your site’s colors and write content that reflects your brand.
          template(v-slot:action)
        NioIconLinkSlat(iconName="display-payment" display-only)
          template(v-slot:title) Send exclusive invites or restrict access
          template(v-slot:subtitle) Notify customers with an email invite or set a site password.
          template(v-slot:action)
      NioButton.start(jumbo-primary @click="customizeShop") Data Shop Settings
</template>

<script>

import { formatNumberVerbose } from '../../modules/helpers'

export default {
  methods: {
    customizeShop() {
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 0
        }
      },"*")
      parent.postMessage({
        name: 'pageNavigation',
        payload: 'shop-settings'
      },"*")
    },
    formatNumber(val) {
      return formatNumberVerbose(val)
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/global/_colors"

.quick-start-page
  display: flex
  flex-direction: column
  align-items: stretch
  .hero
    width: 100%
    height: 801px
    padding-top: 96px
    display: flex
    flex-direction: column
    align-items: center
    text-align: center
    background-image: url('https://cdn.narrative.io/shop-builder/quick-start-hero-banner.svg')
    // background-size: cover
    .nio-p-large
      margin-top: 8px
      opacity: 0.5
    .nio-button
      margin-top: 24px
      margin-bottom: 48px
  .steps
    width: 640px
    margin: 64px auto
    h1
      margin-bottom: 8px
      text-align: center
    p
      margin-bottom: 32px
      text-align: center
    .start
      margin: 0 auto
    .nio-slat-group
      margin-bottom: 32px
  
</style>